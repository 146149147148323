import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`10:00 EMOM of: 1- Full Snatch @ 90% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`9-7-5 reps each of:`}</p>
    <p>{`Power Snatch (135/95)`}</p>
    <p>{`Ring Muscle Ups`}</p>
    <p>{`*`}{`200ft Shuttle Run after each 9/7/5 round.  (4x50ft increment shuttle
run)`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`30-OHS (75/55)`}</p>
    <p>{`30-Cal Row`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      